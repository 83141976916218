/*
  This global is what Klaviyo uses to track events.
  When Klaviyo loads, it'll handle whatever was already placed in this.
*/
_learnq = window._learnq = window._learnq || [];

export function identify() {
  const email = $.cookie('ccmb-email');
  if (!email) return;

  _learnq.push([
    'identify',
    {
      $email: email,
    },
  ]);
}

export function track(eventName, eventData) {
  _learnq.push(['track', eventName, eventData]);
}

export function trackWithEventId(eventName, eventId, eventData) {
  track(eventName, Object.assign({}, eventData, { $event_id: eventId }));
}

export function trackPageView() {
  var page = window.location.href;
  _learnq.push(['track', 'Viewed Page', { url: page }]);
}


export async function bisSubscribe(email, itemId) {
  return fetch('https://a.klaviyo.com/onsite/components/back-in-stock/subscribe', {
    headers: {
      accept: 'application/json, text/plain, */*',
      'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: new URLSearchParams({
      a: _siteConfig.klaviyo,
      email,
      platform: 'api',
      variant: itemId, //replace with a valid variant ID
      product: itemId, // ''      ''  '' ''   product ''
    }),
    method: 'POST',
  });
  // Success looks like {"email": "the email sent", "success": true}
  // Fail looks like {"status": 400, "message": "There was something wrong with your request: * variant   * This field is required."}
}

/*
  Javascript implementation of Java's String.hashCode()
  https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/#comment-38551
*/
function checksum(s) {
  var hash = 0;
  var strlen = s.length;
  var i;
  var c;

  if (strlen === 0) {
    return hash;
  }
  for (i = 0; i < strlen; i++) {
    c = s.charCodeAt(i);
    hash = (hash << 5) - hash + c;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
}
