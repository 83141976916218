import * as AddressValidation from './address/validation';
import * as AddressAutocomplete from './address/autocomplete';
import * as Klaviyo from './klaviyo.js';
import axios from 'axios';
import withConsent from './react-components/src/utils/withConsent';
import services from './react-components/src/services';
import { withGreToken } from './react-components/src/utils/recaptcha';

// Namespace
const CCMB = (window.CCMB = {});

// === Namespaced imports for templates to use begin ===

CCMB.Klaviyo = Klaviyo;
CCMB.Axios = axios; // TODO: Remove after search is reactified
CCMB.AddressValidation = AddressValidation;
CCMB.AddressAutocomplete = AddressAutocomplete;
CCMB.services = services;
CCMB.withGreToken = withGreToken;

// === Namespaced imports for templates to use end ===

CCMB.renderCart = async function () {
  const el = $('#cartItemsCount span');
  if (!el) return;
  el.html('<i class="fa fa-spinner fa-pulse">');
  const response = await axios.get(jsRoutes.controllers.Cart.totalQuantity().url);
  el.html(response?.data?.totalQuantity || 0);
};

CCMB.minicartRequested = async function () {
  const containerEl = $('#MiniShoppingCart, #minicartContents').eq(0);
  if (!containerEl.exists()) return;

  const containerId = containerEl.attr('id');

  const loadingEl = $('<img />', {
    class: 'loading-cart',
    src: require('/public/images/img/loading.gif'),
    style:
      containerId === 'minicartContents'
        ? 'display: block; margin-left: auto; margin-right: auto; padding-bottom: 10px; padding-top: 10px;'
        : undefined,
  });

  containerEl.html(loadingEl);

  try {
    const { data } = await axios.get(jsRoutes.controllers.Cart.cartSummary().url);
    containerEl.html(data);
  } catch (e) {
    containerEl.html(`<ul class="items mini-cart-empty">
      <li>
        Unable to load cart
      </li>
    </ul>`);
  }
};

// === Legacy Address Field Syncing Begin ===

const synchronizedAddressFields = [
  'firstName',
  'lastName',
  'company',
  'address1',
  'address2',
  'city',
  'state',
  'zip',
  'phone',
  'isResidential',
];

const syncAddressField = function (isSame, fieldName, clearBill) {
  if (!isSame && !clearBill) return;

  var sourceField = $('#shipAddress_' + fieldName);

  if (sourceField.is(':checkbox')) {
    $('#billAddress_' + fieldName).prop('checked', isSame ? sourceField.is(':checked') : true);
  } else {
    $('#billAddress_' + fieldName).val(isSame ? sourceField.val() : '');
  }
};

const onAddressSameAsChanged = function () {
  const isSame = $('#sameAsCheckbox').is(':checked');
  const billAddressEl = $('#billingAddress');
  isSame ? billAddressEl.hide() : billAddressEl.show();

  $.each(synchronizedAddressFields, function (i, fieldName) {
    syncAddressField(isSame, fieldName, true);
  });
};

CCMB.initShipBillAddressSync = function () {
  $.each(synchronizedAddressFields, function (i, fieldName) {
    $('#shipAddress_' + fieldName).change(function () {
      const isSame = $('#sameAsCheckbox').is(':checked');
      syncAddressField(isSame, fieldName);
    });
  });

  $('#sameAsCheckbox').change(onAddressSameAsChanged);
};

// === Legacy Address Field Syncing End ===

CCMB.applyMasks = function () {
  // issues with inputmask
  // usually resetting the form resets the html5 input validation, but inputmask runs off and
  // sets an empty value and re-triggers validation (highlighting the fields as invalid if they
  // have required set). so before resetting the form, remove the input mask from the
  // elements and then reapply them.

  // note that there was a bug in 5.0.5 where the remove doesn't not clear out the inputmask event
  // handler that fires on submit and reset, which would leak handlers everytime we do this
  // (hence the current update to 5.0.6.beta.43)

  const zipMask = '99999';
  const zipInputs = $('.mask-zip');

  // We don't do country codes and no US area codes start with 1
  // $.mask.definitions["N"] = "[2-9]";
  const phoneMask = '999-999-9999';
  const phoneInputs = $('.mask-phone');

  const currencyMask = 'currency';
  const currencyInputs = $('.mask-currency');

  zipInputs
    .add(phoneInputs)
    .add(currencyInputs)
    .closest('form')
    .on('reset.applyMasks', function () {
      const form = this;
      const zipInputs = $('.mask-zip', form);
      zipInputs.inputmask('remove');
      const phoneInputs = $('.mask-phone', form);
      phoneInputs.inputmask('remove');
      const currencyInputs = $('.mask-currency', form);
      currencyInputs.inputmask('remove');
      setTimeout(() => {
        zipInputs.inputmask(zipMask);
        phoneInputs.inputmask(phoneMask);
        currencyInputs.inputmask(currencyMask);
      });
    });

  zipInputs.inputmask(zipMask);
  phoneInputs.inputmask(phoneMask);
  currencyInputs.inputmask(currencyMask);
};

const riskifiedBeaconLoad = function () {
  var store_domain = window.site_url;
  var session_id = $.cookie('ccmb-sid');
  var url =
    ('https:' == document.location.protocol ? 'https://' : 'http://') +
    'beacon.riskified.com?shop=' +
    store_domain +
    '&sid=' +
    session_id;
  var s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = url;
  var x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);
};

const setupLocationChangeHandling = function () {
  history.pushState = ((f) =>
    function pushState() {
      var ret = f.apply(this, arguments);
      window.dispatchEvent(new Event('pushstate'));
      window.dispatchEvent(new Event('locationchange'));
      return ret;
    })(history.pushState);

  history.replaceState = ((f) =>
    function replaceState() {
      var ret = f.apply(this, arguments);
      window.dispatchEvent(new Event('replacestate'));
      window.dispatchEvent(new Event('locationchange'));
      return ret;
    })(history.replaceState);

  window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'));
  });
};

CCMB.initLoggedInMenu = function () {
  const userName = $.cookie('ccmb-name');
  const siteId = window.siteId ?? 2;
  if (userName?.trim()) {
    if (siteId === 2) {
      $('.plum-menu__logged').html(`
      <div class="tw-relative tw-text-white tw-font-semibold tw-inline-block tw-cursor-pointer tw-transition tw-duration-200 [&>.plum-icon]:tw-h-[32px] [&>.plum-icon]:tw-w-[32px] [&>.plum-icon]:md:!tw-h-[24px] [&>.plum-icon]:md:!tw-w-[24px] [&>.plum-icon]:!tw-mr-0 plum-menu-dropdown-toggle" data-target="#MyAccountNavMenu">
            <img src="/assets/images/rtic/rebranded/icon-user.svg" aria-label="Account" role="button" class="tw-w-full tw-max-h-7 tw-max-w-7" />
            <div id="MyAccountNavMenu" class="plum-menu-dropdown tw-absolute tw-z-[100] tw-top-[50px] tw-left-0 tw-right-0 tw-bg-white tw-shadow-[#00000020_0_6px_15px] tw-rounded-lg tw-p-3 tw-hidden [&_ul]:tw-pl-0 [&_ul]:tw-mb-0 [&_ul]:tw-list-none after:tw-absolute after:tw-top-[-9px] after:tw-right-0 after:tw-m-auto after:tw-content-[' '] after:tw-h-0 after:tw-w-0 after:tw-border-l-[10px] after:tw-border-r-[10px] after:tw-border-b-[10px] after:tw-border-l-transparent after:tw-border-r-transparent after:tw-border-b-white tw-w-[300px] -tw-translate-x-[220px] after:tw-left-[165px]">
              <ul>
                <li>
                  <a href="${jsRoutes.controllers.AccountController.myAccount().url}"
                    ><span class="plum-icon plum-icon-my-account plum-icon-black" aria-label="My account"></span> View My Account</a
                  >
                </li>
                <li>
                  <a href="${jsRoutes.controllers.AccountController.myAccount().url}#Orders"
                    ><span class="plum-icon plum-icon-my-orders plum-icon-black" aria-label="My orders"></span> View My Orders</a
                  >
                </li>
                <li><a class="twc-btn twc-btn--primary tw-max-w-[calc(100%_-_16px)] tw-text-center tw-w-full tw-mx-2" href="${
                  jsRoutes.controllers.UserController.signOut().url
                }">sign out</a></li>
              </ul>
            </div>
          </div>
    `);
    } else {
      $('.plum-menu__logged').html(`
      <div class="plum-menu__links-item plum-menu__dropdown-toggle" data-target="#MyAccountNavMenu">
        <img src="/assets/images/rtic/rebranded/icon-user.svg" aria-label="Account" role="button" class="tw-w-full tw-max-h-7 tw-max-w-7" />
        <p>${userName} Account</p>
        <div id="MyAccountNavMenu" class="plum-menu__links-item__dropdown">
          <ul>
            <li>
              <a href="${jsRoutes.controllers.AccountController.myAccount().url}"
                ><span class="plum-icon plum-icon-my-account plum-icon-black" aria-label="My account"></span> View My Account</a
              >
            </li>
            <li>
              <a href="${jsRoutes.controllers.AccountController.myAccount().url}#Orders"
                ><span class="plum-icon plum-icon-my-orders plum-icon-black" aria-label="My orders"></span> View My Orders</a
              >
            </li>
            <li><a class="twc-btn twc-btn--primary" href="${
              jsRoutes.controllers.UserController.signOut().url
            }">sign out</a></li>
          </ul>
        </div>
      </div>
    `);
    }

    $('#mobMyAccountLink').remove();

    $('.plum-mob-menu__content').prepend(`
      <div class="plum-mob-menu__logged-in-links tw-pb-3">
        <ul class="plum-mob-menu__menu-links plum-mob-menu__menu-links--w-icons plum-mob-menu__menu-links--compact">
          <li>
            <a href="${jsRoutes.controllers.AccountController.myAccount().url}">
              <div class="plum-mob-menu__img-wrapper">
                <span class="plum-icon plum-icon-my-account"></span>
              </div>
              <span>${userName} Account</span>
            </a>
          </li>
          <li>
            <a href="${jsRoutes.controllers.AccountController.myAccount().url}#Orders">
              <div class="plum-mob-menu__img-wrapper">
                <span class="plum-icon plum-icon-my-orders"></span>
              </div>
              <span>View My Orders</span>
            </a>
          </li>
        </ul>

        <div class='tw-text-center'>
          <a href="${
            jsRoutes.controllers.UserController.signOut().url
          }" class="twc-btn twc-btn--primary">Sign out</a>
        </div>
      </div>
    `);
  }
};

CCMB.initStickyHeader = function () {
  if ('IntersectionObserver' in window) {
    // current code eliminates errors but it doesn't work on Safari < 12.2, IE, Edge < 14
    const stickyMenuObserver = new IntersectionObserver(
      ([e]) => {
        const scrolled = e.intersectionRatio === 0;
        const el = document.querySelector('.plum-menu__sticky');
        el.classList.toggle('plum-menu__scrolled', scrolled);
        el.classList.toggle('menuFadeInDown', scrolled);
      },
      { threshold: [0] }
    );
    stickyMenuObserver.observe(document.querySelector('.plum-menu__sticky-anchor'));
  }
};

$(function () {
  setupLocationChangeHandling();

  withConsent('marketing', () => {
    try {
      Klaviyo.identify();
    } catch (e) {
      console.log('Unable to initiate Klaviyo');
    }
  });

  // DEPRECATED: If any page specific logic is defined, run after all of the general logic is complete
  if (typeof window.runPageSpecificLogic === 'function') {
    window.runPageSpecificLogic();
  }

  // Run additional page-specific logic
  window.logicLayer && window.logicLayer.start();

  if (!window.nojs) {
    riskifiedBeaconLoad();

    withConsent('marketing', () => {
      try {
        loadZendeskWidget();
      } catch (e) {
        console.log('Unable to load zendesk widget: ' + e);
      }
    });
  } else {
    window.prerenderReady = true;
  }
});

CCMB.showChat = () => {
  if (typeof $zopim !== 'undefined') {
    $zopim.livechat.window.show();
  } else if (typeof zE !== 'undefined') {
    zE('messenger', 'open');
  }
};

CCMB.withConsent = withConsent;

CCMB.trackClick = (eventType, target) => {
  switch (eventType) {
    case 'select_item':
      const { listName, listId } = $(target).data();
      const text = $(target).text().trim();
      CCMB.services.googleAnalytics.selectItemEvent(
        {
          item_name: text,
        },
        listId,
        listName
      );
      break;
    case 'select_content':
      let ariaLabel = '';

      if ($(target).attr('aria-label')) {
        ariaLabel = $(target).attr('aria-label').trim();
      } else {
        let childWithAriaLabel = $(target).find('[aria-label]').first();
        if (childWithAriaLabel.length) {
          ariaLabel = childWithAriaLabel.attr('aria-label').trim();
        }
      }
      CCMB.services.googleAnalytics.selectContentEvent(ariaLabel);
      break;
    case 'select_promotion':
      const { creativeName, creativeSlot, promotionName } = $(target).data();
      const promotionId = location.pathname;
      CCMB.services.googleAnalytics.selectPromotionEvent(
        creativeName,
        creativeSlot,
        promotionName,
        promotionId === '/' ? 'Home' : promotionId
      );
      break;
  }
};

/*
const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    let re = /maximum\-scale=[0-9\.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};
const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;
const isIOSDevice = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
if (isIOSDevice()) disableIosTextFieldZoom();
 */
